import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import * as _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";

import TextField from "@material-ui/core/TextField";
import { Alert, Autocomplete } from "@material-ui/lab";
import { MuiThemeProvider, Switch, createTheme } from "@material-ui/core";
import moment from "moment";

var theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "14px 10px 14px 16px"
      }
    },
    MuiInput: {
      root: {
        fontSize: "13px"
      }
    }
  }
});

export default function ModifyUsers(props) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [state, setState] = React.useState("");
  const cargos = useSelector(state => state.cargosReducer.cargos);
  const profiles = useSelector(state => state.userReducer.profiles);
  const languages = useSelector(state => state.userReducer.languages);

  const dispatch = useDispatch();

  const resetPassword = row => {
    dispatch(userActions.resetPassword(row.id));
  };

  useEffect(() => {
    if (props.users) {
      var profilesJSON = {};
      profiles.forEach(item => {
        profilesJSON[item.id] = item.nombre;
      });
      let centrosJSON = {};
      props.centros.forEach(item => {
        centrosJSON[item.id] = item.nombre;
      });
      let cargosJSON = {};
      cargos.forEach(item => {
        cargosJSON[item.id] = item.nombre;
      });
      const languageJSON = {};
      languages.forEach(item => {
        languageJSON[item.id] = item.name;
      });

      const centros = props.centros;
      setState({
        columns: [
          {
            title: "id",
            field: "id",
            editable: "never",
            hidden: "true",
            cellStyle: {
              color: "grey",
              width: "50%"
            },
            headerStyle: {
              width: "50%",
              color: "grey"
            }
          },
          {
            title: "Resetear",
            field: "id",
            export: false,
            editable: "never",
            render: rowData => (
              <Button size="small" color="primary" onClick={() => resetPassword(rowData)}>
                Resetear contraseña
              </Button>
            )
          },
          { title: "Nombre", field: "nombre" },
          { title: "Apellidos", field: "apellidos" },
          { title: "Email", field: "email" },
          { title: "Teléfono", field: "telefono" },
          {
            title: "Perfil",
            field: "perfil",
            lookup: profilesJSON
          },
          {
            title: "Fecha Alta",
            field: "created_at",
            render: rowData => moment(rowData.created_at).format('DD/MM/yy HH:mm'),
            editable: "never",
          },
          {
            title: "Idioma",
            field: "language_id",
            lookup: languageJSON
          },
          { title: "Cargo", field: "cargos_id", lookup: cargosJSON },
          {
            title: "Activo",
            field: "activo",
            export: false,
            render: rowData => (
              <Switch
                disabled={true}
                color="secondary"
                checked={rowData.activo === 1 ? true : false}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            ),
            editComponent: props => (
              <Switch
                color="primary"
                onChange={e =>
                  e.target.checked === true
                    ? props.onChange(1)
                    : props.onChange(0)
                }
                checked={props.value === 1 ? true : false}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            )
          },
          {
            title: "Centro",
            field: "centros_id",
            lookup: centrosJSON,
            editComponent: props => {
              return (
                <Autocomplete
                  id="centro"
                  debug
                  options={centros ? centros : [{ nombre: "" }]}
                  getOptionLabel={option => option.nombre }
                  style={{ width: 170, heigh: 70 }}
                  onChange={(event, value) => {
                    props.onChange(value);
                  }}
                  value={
                    props.value && typeof props.value === "number"
                      ? _.find(centros, { id: props.value })
                      : props.value
                  }
                  className="fontSame"
                  renderInput={params => (
                    <TextField {...params} margin="none" />
                  )}
                />
              );
            }
          },
          {
            title: "CCAA",
            field: "ccaa",
            editable: "never",
          },
          {
            title: "Provincia",
            field: "provincia",
            editable: "never",
          },
          {
            title: "Municipio",
            field: "municipio",
            editable: "never",
          },
          {
            title: "Código",
            field: "codigo",
            editable: "never",
          },
          {
            title: "Etiqueta Grupo",
            field: "groupby",
            editable: "never",
          },
          {
            title: "Titularidad",
            field: "titularidad",
            editable: "never",
          },
          {
            title: "CEPA/FP",
            field: "cepa_fp",
            editable: "never",
          },
          {
            title: "C.P.",
            field: "cp",
            editable: "never",
          },
        ],
        data: props.users
      });
    }
  }, [props.users, props.profiles, props.centros, props.cargos]);

  return (
    <div>
      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title="Editar Usuarios"
          columns={state.columns}
          data={state.data}
          options={{
            exportButton: {
              csv: true,
            }, 
            exportAllData: true,
            exportDelimiter: ';',
            exportFileName: 'Listado de usuarios Xcelence'
          }}
          localization={{
            body: {
              editRow: {
                deleteText: '¿Estás seguro(a) en eliminar al usuario?'
              },
            },
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                console.log(newData);

              var dataValues = _.values(newData);

              if (_.without(dataValues, "").length < 8) {
                reject(
                  setErrorMessage("Todos los campos deben estar completados")
                );
              } else {
                console.log(newData);
                setErrorMessage(null);
                dispatch(userActions.newUser(newData));
                resolve();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              var dataValues = _.values(newData);

                if (_.without(dataValues, "").length < 11) {
                  reject(
                    setErrorMessage("Todos los campos deben estar completados")
                  );
                } else {
                  if (typeof newData.centros_id != "number") {
                    newData.centros_id = newData.centros_id.id;
                  }
                  setErrorMessage(null);
                  dispatch(userActions.updateUser(newData));
                  resolve();
                }
              }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              dispatch(userActions.deleteUser({
                id: oldData.id,
                resolve,
                reject
              }));
            }).catch(() => setErrorMessage('No fue posible eliminar la cuenta.'))
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
